<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <ERInfoPopup v-if="pop.isShow" @close="pop.isShow=false"></ERInfoPopup>

        <CarrotTitle title="E.R (Execution Report)">
            <button @click="pop.isShow=true" class="btn-info ml-230">info</button>
            - View 클릭 시 최근 작성된 E.R을 확인할 수 있습니다.<br>
            - 진행(진척율 0~90%), 완료(진척율100%), 보류/중지/기타(진척율 보류, 중지, 기타)
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>

                    <div class="progress-area">
                        <div class="legend-box">
                            <span class="dot complete"></span>
                            <span class="txt">완료</span>
                            <span class="dot Proceeding"></span>
                            <span class="txt">진행</span>
                            <span class="dot"></span>
                            <span class="txt">보류/중지/기타</span>
                        </div>
                        <div class="progress-box">
                            <span v-if="bbs.isMounted" class="text">{{ bbs.info.team_name?bbs.info.team_name:bbs.info.office_name }} {{ bbs.info.ename }} ({{ bbs.info.kname }} )</span>
                            <div class="progress">
                                <div class="bar w-100per">
                                    <span v-if="bbs.info.cnt_etc>0" class="txt">{{ bbs.info.cnt_etc }}</span>
                                </div>
                                <div class="bar Proceeding" :style="{ 'width':(bbs.info.per_done+bbs.info.per_proc)+'%' }">
                                    <span v-if="bbs.info.cnt_proc>0" class="txt mr-10">{{ bbs.info.cnt_proc }} </span>
                                </div>
                                <div class="bar complete" :style="{ 'width':bbs.info.per_done+'%' }">
                                    <span v-if="bbs.info.cnt_done>0" class="txt mr-10">{{ bbs.info.cnt_done }} </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-40 mb-20">

                        <carrot-sector v-if="bbs.isMounted" @change="bbs.doInit" v-model="bbs.sector" class="w-200px"></carrot-sector>
                        <carrot-dept v-if="bbs.isMounted" @change="bbs.doInit" :idx_sector="bbs.sector" v-model="bbs.office" class="w-200px"></carrot-dept>
                        <carrot-team v-if="bbs.isMounted" @change="bbs.doInit" :idx_sector="bbs.sector" :idx_office="bbs.office" v-model="bbs.team" class="w-200px ml-10"></carrot-team>
                        <select v-model="bbs.syear" @change="bbs.doInit" class="w-100px ml-10">
                                <option :value="v" v-for="(v, k) in bbs.search_years" :key="k">{{ v }}</option>
                            </select>
                        <select v-model="bbs.smonth" @change="bbs.doInit" class="w-100px ml-10">
                            <option :value="(i>9?i:'0'+i)" v-for="i in 12" :key="i">{{ (i>9?i:'0'+i) }}</option>
                        </select>
                        <button @click="bbs.addER()" class="btn-default float-right">E.R 작성하기</button>
                        <div class="clear"></div>
                    </div>
                    
                    <table class="table-col">
                        <colgroup>
                            <col width="180">
                            <col width="270">
                            <col width="180">
                            <col width="180">
                            <col width="180">
                            <col width="180">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>최근작성일</th>
                                <th>Name</th>
                                <th>E.R</th>
                                <th>진행</th>
                                <th>완료</th>
                                <th>보류/중지/기타</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in bbs.list" :key="i">
                                <td>{{ irow.wdate }}</td>
                                <td>{{ irow.ename }} ({{ irow.kname }})</td>
                                <td><span @click="bbs.showDetail(irow.idx)" class="btn-view">View</span></td>
                                <td>{{ irow.cnt_proc }}</td>
                                <td><span @click="bbs.showCompleteList(irow.idx_hq)" class="btn-view">{{ irow.cnt_done }}</span></td>
                                <td><span @click="bbs.showEtcList(irow.idx_hq)" class="btn-view">{{ irow.cnt_etc }}</span></td>
                            </tr>
                            <tr v-if="bbs.total==0">
                                <td colspan="6">E.R 정보가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    <CarrotPaging :total="bbs.total" :list_per_page="bbs.rows" v-model="bbs.page" @change="bbs.doSearch"></CarrotPaging>

                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'

import CarrotSector from '@/components/common/CarrotSector.vue'
import CarrotDept from '@/components/common/CarrotDept.vue'
import CarrotTeam from '@/components/common/CarrotTeam.vue'
import ERInfoPopup from '@/components/popup/BusinessManagement/ERInfoPopup.vue'
import { useStore } from 'vuex';


export default {
    layout:"BusinessManagement",
    components: {
        CarrotSector,
        CarrotDept,
        CarrotTeam,
        ERInfoPopup
    },
    setup() {
        const router = new useRouter();
        const toast  = useToast();
        const store  = useStore();

        const pop = reactive({
            // Popup 노출 여부
            isShow: false
        });

        const bbs = reactive({
            isMounted    : false,
            search_years : [],

            page : 1,
            rows : 50,

            sector : 0,
            office : 0,
            team   : 0,
            syear  : "",
            smonth : "",

            info : {},
            list : [], total : 0,

            doInit : () => {
                bbs.page = 1;
                bbs.doSearch();
            },

            showDetail : (idx) => {
                sessionStorage.setItem('ERList', JSON.stringify({
                    page   : bbs.page,
                    syear  : bbs.syear,
                    smonth : bbs.smonth,
                    sector : bbs.sector,
                    office : bbs.office,
                    team   : bbs.team
                }));

                router.push({ 
                    name:'BusinessManagement-ERView-idx', 
                    params:{ 
                        idx:idx 
                    } 
                });
            },

            showCompleteList : (idx) => {
                sessionStorage.setItem('ERList', JSON.stringify({
                    page   : bbs.page,
                    syear  : bbs.syear,
                    smonth : bbs.smonth,
                    sector : bbs.sector,
                    office : bbs.office,
                    team   : bbs.team
                }));

                router.push({ 
                    name:'BusinessManagement-ERCompleteList-idx', 
                    params:{ 
                        idx:idx 
                    } 
                });
            },

            showEtcList : (idx) => {
                sessionStorage.setItem('ERList', JSON.stringify({
                    page   : bbs.page,
                    syear  : bbs.syear,
                    smonth : bbs.smonth,
                    sector : bbs.sector,
                    office : bbs.office,
                    team   : bbs.team
                }));

                router.push({ 
                    name:'BusinessManagement-EREtcList-idx', 
                    params:{ 
                        idx:idx 
                    } 
                });
            },

            addER : () => {
                router.push({
                    name : 'BusinessManagement-ERAdd'
                });
                // if( bbs.info.last_idx > 0 ){
                //     router.push({
                //         name : 'BusinessManagement-ERMod-idx',
                //         params:{
                //             idx:bbs.info.last_idx
                //         }
                //     });
                // } else {
                //     router.push({
                //         name : 'BusinessManagement-ERAdd'
                //     });
                // }
            },

            doSearchInfo : () => {
                let params = { };

                axios.get("/rest/businessmgr/getMyERInfo", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.info = res.data;

                        bbs.office = bbs.info.idx_office;
                        bbs.team   = bbs.info.idx_team;

                        bbs.isMounted = true;
                        bbs.doSearch();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }

                    sessionStorage.removeItem('ERList');
                });
            },

            doSearch : () => {
                if( bbs.isMounted == false ) return;
                let params = {
                    page   : bbs.page,
                    rows   : bbs.rows,
                    syear  : bbs.syear,
                    smonth : bbs.smonth,
                    sector : bbs.sector,
                    office : bbs.office,
                    team   : bbs.team
                };

                axios.get("/rest/businessmgr/getERList", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.list  = res.data.list;
                        bbs.total = res.data.total;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }

                    sessionStorage.removeItem('ERList');
                });
            },

            makeSearchYears: () => {
                let cd = new Date();
                let cy = cd.getFullYear();
                for(let i=cy; i>=2020; i--){
                    bbs.search_years.push(i);
                }
            }
        });

        onMounted(() => {
            // Mounted
            let nd = new Date();
            bbs.syear  = nd.getFullYear();
            bbs.smonth = (nd.getMonth()>8?nd.getMonth()+1:'0'+(nd.getMonth()+1));
            bbs.makeSearchYears();

            let ss = sessionStorage.getItem("ERList");
            if( ss ){
                ss = JSON.parse(ss);
                bbs.page  = ss.page?ss.page:1;
                if( ss.syear  ) bbs.syear  = ss.syear;
                if( ss.smonth ) bbs.smonth = ss.smonth;
                bbs.office = ss.office>0?ss.office:0;
                bbs.team   = ss.team>0?ss.team:0;
            } else {
                console.log(store.state.idx_office);
                bbs.office = !store.state.idx_office?0:store.state.idx_office;
                bbs.team   = !store.state.idx_team?0:store.state.idx_team;
            }

            bbs.doSearchInfo();
        });

        return {pop, bbs};
    }
}
</script>

<style lang="scss" scoped>
</style>