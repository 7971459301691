<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-550px">
            <div>
                <p class="title">E.R 작성 시 주의사항</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <div class="txt-left pt-30 pb-10">
                    - 등록된 업무 중 진척율이 100%가 아닌 업무는 당일 E.R 작성 시 자동으로 불러옵니다.<br>&nbsp;&nbsp;(보류/중지/기타 제외)<br>
                    - 시작일은 저장 후 수정할 수 없습니다.<br>
                    - 완료일은 저장 후 변경 및 수정 저장 할 때 마다 변경 차수가 1회씩 추가됩니다.<br>
                    - 작성 및 저장된 업무는 삭제가 불가능합니다. (수정 혹은 중지로 상태 변경)
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue'

export default {
    name: 'ERInfoPopup',
    props: {
        idx: {
            type: Number,
            default: 0,
        }
    },
    setup(props, {emit}) {
        const pop = reactive({
            onClose: () => {
                emit('close');
            }
        });
        
        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>